import { useEffect } from 'react'

export const useDecorateParams = (name) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const url = new URL(window.location.href)
      url.searchParams.set('name', name.replace(/\s/g, '-'))
      window.history.pushState(null, '', url.toString())
    }
  }, [name])
}
