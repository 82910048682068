import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { deserialize } from 'deserialize-json-api'
import { get, isEqual, isEmpty } from 'lodash'

import SEO from '../../atoms/Seo'
import Navbar from '../../organisms/Navbar'
import Footer from '../../organisms/Footer'
import ArticlePage from '../../organisms/ArticlePage'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import { useTabTitle } from '../../../hooks/useTabTitle'
import { useDecorateParams } from '../../../hooks/useDecorateParams'
import { API_SALES_URL } from '../../../config'

const Title = styled(HomeSectionTitle)`
  text-align: center;
  margin: 108px 0 74px 0;
`

const ArticleTemplate = ({ id, location }) => {
  const [article, updateArticle] = useState(null)
  const [error, setError] = useState('')
  const [loading, updateLoading] = useState(false)
  const articleName = get(article, 'title', '')

  useTabTitle(articleName)
  useDecorateParams(articleName)

  const fetchArticle = useCallback(async () => {
    updateLoading(true)

    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/articles/${id}`)

      updateArticle(get(deserialize(data), 'data'))
    } catch (err) {
      const response = get(err, 'response.data.message', '')
      if (isEqual(response, 'Article archived')) {
        setError('Article supprimé')
      } else {
        setError('Impossible de charger cet article.')
      }
    } finally {
      updateLoading(false)
    }
  }, [updateLoading, updateArticle])

  useEffect(() => {
    fetchArticle()
  }, [])

  const title = 'Article de TrouverMonLocalPro'
  const description = 'TrouverMonLocalPro vous propose en exclusivité les meilleurs articles concernant les annonces de location et vente dans toutes la France'

  return (
    <>
      <SEO
        title={title}
        location={location}
        description={description}>
        </SEO>
      <Navbar location={location} />
      {isEmpty(error) && <ArticlePage loading={loading} article={article} />}
      {!isEmpty(error) && <Title>{error}</Title>}
      <Footer />
    </>
  )
}

ArticleTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.object
}

export default ArticleTemplate
