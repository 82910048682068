import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, map, isEqual } from 'lodash'

// import media from '../../../utils/media'
import LoadingProgram from '../../atoms/LoadingProgram'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { formatDate } from '../../../utils/date'

const Container = styled.div`
  margin-top: 72px;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 78px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const BigTitle = styled(HomeSectionTitle)`
  font-size: 30px;
  margin-top: 24px;
`
const Image = styled.img`
  margin-top: 32px;
  margin-bottom: 12px;
  height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
`
const Link = styled.a`
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
`
const Title = styled(HomeSectionTitle)`
  margin-top: 42px;
`
const Paragraph = styled.div`
  margin-top: 24px;
`
const LinkContainer = styled.div`
  margin-top: 16px;
`

const ArticleContent = ({ loading, article }) => {
  if (loading) {
    return (
      <LoadingProgram loading={loading} />
    )
  }

  return (
    <Content>
      <BigTitle tag='h1'>
        {get(article, 'title', '')}
      </BigTitle>
      <HomeSectionDescription>Article rédigé le {formatDate(new Date(get(article, 'created_at')))}</HomeSectionDescription>
      <Image src={get(article, 'image')} />
      <HomeSectionSubtitle>{get(article, 'subtitle', '')}</HomeSectionSubtitle>
      {map(get(article, 'paragraphs'), paragraph => {
        const label = get(paragraph, 'text_link', '')

        return (
          <>
            <Title tag='h2'>{get(paragraph, 'title')}</Title>
            <Paragraph>
              <HomeSectionSubtitle>{get(paragraph, 'subtitle1')}</HomeSectionSubtitle>
              <HomeSectionDescription>{get(paragraph, 'content1')}</HomeSectionDescription>
            </Paragraph>
            <Paragraph>
              <HomeSectionSubtitle>{get(paragraph, 'subtitle2')}</HomeSectionSubtitle>
              <HomeSectionDescription>{get(paragraph, 'content2')}</HomeSectionDescription>
            </Paragraph>
            {!isEqual(label) &&
              <LinkContainer>
                <HomeSectionSubtitle>
                  <Link href={get(paragraph, 'link', '')}>
                    {label}
                  </Link>
                </HomeSectionSubtitle>
              </LinkContainer>
            }
          </>
        )
      })}
    </Content>
  )
}

ArticleContent.propTypes = {
  article: PropTypes.object,
  loading: PropTypes.bool.isRequired
}

const ArticlePage = ({ article, loading }) => {
  return (
    <Container>
      <ArticleContent article={article} loading={loading} />
    </Container>
  )
}

ArticlePage.propTypes = {
  article: PropTypes.object,
  loading: PropTypes.bool.isRequired
}

export default ArticlePage
