import React from 'react'
import PropTypes from 'prop-types'

import ArticleTemplate from '../../components/templates/ArticleTemplate'

const ArticlePage = ({ id, location }) => <ArticleTemplate id={id} location={location} />

ArticlePage.propTypes = {
  id: PropTypes.string,
  location: PropTypes.object
}

export default ArticlePage
