import { useRef, useEffect } from 'react'

export const useTabTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(typeof document !== 'undefined' ? document.title : '');

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = title;
    }
  }, [title])

  useEffect(() => () => {
    if (!prevailOnUnmount && typeof document !== 'undefined') {
      document.title = defaultTitle.current;
    }
  }, [])
}
